import request from '@/utils/request'


// 查询用户照片墙列表
export function listUser(query) {
  return request({
    url: '/biz/userImg/list',
    method: 'get',
    params: query
  })
}

// 查询用户照片墙分页
export function pageUser(query) {
  return request({
    url: '/biz/userImg/page',
    method: 'get',
    params: query
  })
}

// 查询用户照片墙详细
export function getUser(data) {
  return request({
    url: '/biz/userImg/detail',
    method: 'get',
    params: data
  })
}

// 新增用户照片墙
export function addUser(data) {
  return request({
    url: '/biz/userImg/add',
    method: 'post',
    data: data
  })
}

// 修改用户照片墙
export function updateUser(data) {
  return request({
    url: '/biz/userImg/edit',
    method: 'post',
    data: data
  })
}

// 删除用户照片墙
export function delUser(data) {
  return request({
    url: '/biz/userImg/delete',
    method: 'post',
    data: data
  })
}
